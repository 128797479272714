import Link from 'next/link';
import React from 'react';
import slugify from 'slugify';
import navItems from '~/data/header-nav';

function HeaderNav({ headerDark, headerBg }) {

  return (
    <nav className="justify-end hidden ml-auto xl:flex">
      {navItems?.map((item, i) => (
        <li key={item + i} className={`relative block list-none py-4 ${item.subItems?.length >= 1 || item?.multilevelSubItems?.length > 0 ? 'has-sub' : ''}`}>
          <Link href={item.link}>
            <a
              className={`pr-6 xl:pr-8 flex justify-between items-center text-base font-medium transition-colors duration-200 ease-in-out ${
                headerDark && !headerBg ? 'text-white' : 'text-black'
              } hover:text-accent`}>
              {item.title}
              {item.subItems?.length > 0 || item?.multilevelSubItems?.length > 0 ? (
                <div className="flex items-center w-3 h-3 ml-2">
                  <svg width="100%" height="100%" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.5 9.5L0.5 0.5" stroke="#F3B545" strokeLinejoin="round" />
                    <path d="M3.5 9.5H9.5V3.5" stroke="#F3B545" />
                  </svg>
                </div>
              ) : null}
            </a>
          </Link>

          {item.subItems?.length >= 1 ? (
            <div
              className={`absolute inset-x-auto hidden w-screen ${item.alignment === 'vertical' ? 'max-w-sm' : 'max-w-2xl'}  p-8 mt-4 bg-white header__subnav`}
              data-menu={item.title}
              style={{
                maxWidth: item.subMaxWidth ? item.subMaxWidth : ''
              }}>
              <div className="transition-opacity duration-200 ease-in-out opacity-0 header__subnav__cont w-full">
                {item.subTitle ? (
                  <div className="pb-4 mb-6 border-b border-dashed w-full" style={{ borderColor: 'rgba(0,0,0,0.1)' }}>
                    <p className="font-medium">{item.subTitle}</p>
                  </div>
                ) : null}
                <ul className={`grid ${item.alignment === 'vertical' ? 'grid-cols-1' : 'grid-cols-3'} gap-6 list-none`}>
                  {item.subItems.map((subItem, i) => (
                    <li key={subItem + i}>
                      <Link href={subItem.link}>
                        <a className="flex items-center rounded-md subnav-item" data-thumb={slugify(subItem.title)}>
                          <div className="w-10 h-10 mr-4 bg-tertiary flex flex-col items-center justify-center subnav-item-icon" style={{ flex: '0 0 40px' }}>
                            {subItem?.icon ? <img src={`/images/menu/${subItem.icon}`} alt={slugify(subItem.title)} loading="lazy" /> : null}
                          </div>
                          <p className="text-sm font-medium leading-tight">{subItem.title}</p>
                        </a>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ) : null}

          {item.multilevelSubItems?.length > 0 ? <MultilevelSubNav items={item.multilevelSubItems} /> : null}
        </li>
      ))}
    </nav>
  );
}

export default HeaderNav;


function MultilevelSubNav({items}) {
  return (
    <div className={`absolute inset-x-auto hidden w-screen max-w-2xl  p-8 mt-4 bg-white header__subnav`}>
      <div className="transition-opacity duration-200 ease-in-out opacity-0 header__subnav__cont w-full space-y-8">
        {items.map(rs => (
          <div key={`sub-${rs?.heading}`}>
            <div className="pb-4 mb-6 border-b border-dashed w-full" style={{ borderColor: 'rgba(0,0,0,0.1)' }}>
              <p className="font-medium" dangerouslySetInnerHTML={{ __html: rs.heading }} />
            </div>
            <ul className={`grid ${rs.alignment === 'vertical' ? 'grid-cols-1' : 'grid-cols-3'} gap-6 list-none`}>
              {rs.items.map((subItem, i) => (
                <li key={subItem + i}>
                  <Link href={subItem.link}>
                    <a className="flex items-center rounded-md subnav-item" data-thumb={slugify(subItem.title)}>
                      <div className="w-10 h-10 mr-4 bg-tertiary flex flex-col items-center justify-center subnav-item-icon" style={{ flex: '0 0 40px' }}>
                        {subItem?.icon ? <img src={`/images/menu/${subItem.icon}`} alt={slugify(subItem.title)} loading="lazy" /> : null}
                      </div>
                      <p className="text-sm font-medium leading-tight">{subItem.title}</p>
                    </a>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
}