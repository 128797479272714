import React, { useEffect, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { useRouter } from 'next/router';
import Link from 'next/link';
import HeaderNav from './HeaderNav';
import MobileNav from './MobileNav';

function Logo({ color = 'white' }) {
  const hexCode = color === 'white' ? '#FFFFFF' : '#1C1F26';

  return (
    <div style={{ height: '32px', width: '96px' }}>
      <svg width="85" height="32" viewBox="0 0 85 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M32 9.33344H37.8958C41.4217 9.33344 44.0517 11.9968 44.0517 15.3401C44.0517 18.6834 41.4217 21.3468 37.8669 21.3468H35.2658V26.1351H32V9.33344ZM35.2658 12.3084V18.3718H37.6646C39.5431 18.3718 40.7859 16.9834 40.7859 15.3401C40.7859 13.6968 39.5431 12.3084 37.6646 12.3084H35.2658Z"
          fill={`${hexCode}`}
        />
        <path
          d="M65.2972 20.355C65.2972 17.04 67.985 14.3767 71.3664 14.3767C74.7478 14.3767 77.4356 17.04 77.4356 20.355C77.4356 23.67 74.7478 26.3333 71.3664 26.3333C67.985 26.3333 65.2972 23.67 65.2972 20.355ZM74.3143 20.355C74.3143 18.7117 72.9849 17.38 71.3664 17.38C69.6902 17.38 68.4474 18.74 68.4474 20.355C68.4474 21.9983 69.7191 23.33 71.3664 23.33C72.9849 23.3583 74.3143 21.9983 74.3143 20.355Z"
          fill={`${hexCode}`}
        />
        <path
          d="M52.348 20.355C52.348 17.04 55.0357 14.3767 58.4172 14.3767C61.7986 14.3767 64.4863 17.04 64.4863 20.355C64.4863 23.67 61.7986 26.3333 58.4172 26.3333C55.0357 26.3333 52.348 23.67 52.348 20.355ZM61.365 20.355C61.365 18.7117 60.0356 17.38 58.4172 17.38C56.7409 17.38 55.4982 18.74 55.4982 20.355C55.4982 21.9983 56.7698 23.33 58.4172 23.33C60.0356 23.3583 61.365 21.9983 61.365 20.355Z"
          fill={`${hexCode}`}
        />
        <path
          d="M77.6014 14.6317C78.3801 14.6317 78.8168 14.6317 79.5955 14.6317V14.32C79.5955 10.58 80.8383 9.33334 84.7399 9.33334H85V12.28C83.0347 12.28 82.8035 12.5633 82.8035 14.3483V14.6317H85V17.465H82.8035V26.135H79.5955V17.4367C78.8168 17.4367 78.3801 17.4367 77.6014 17.4367V14.6317Z"
          fill={`${hexCode}`}
        />
        <path
          d="M45.0464 14.6317H48.2255V16.5017C48.8035 15.3117 49.8729 14.6317 51.4624 14.6317H52.1644V17.6917H50.711C49.0058 17.6917 48.2544 18.57 48.2544 20.695V26.1067H45.0464V14.6317Z"
          fill={`${hexCode}`}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.19757 1.10157L5.65494 2.54319C4.26887 6.22559 4.09712 9.83196 5.00459 12.4679C6.69679 11.0403 8.83944 10.2231 11.4369 10.2231C14.8976 10.2231 17.1882 12.3985 17.1942 15.1066C17.2001 17.822 14.9096 19.997 11.4369 19.997C9.04947 19.997 6.64723 19.2876 4.83346 17.6672C3.13536 21.1433 3.0524 25.1119 3.0524 28.9135H7.61943V23.4708H11.4369C14.7668 23.4708 17.1257 22.3607 18.6475 20.8263C20.1828 19.2782 20.9592 17.2063 20.9592 15.117C20.9592 13.0277 20.1828 10.9558 18.6475 9.40767C17.1257 7.87333 14.7668 6.76322 11.4369 6.76322L9.62878 6.764V3.67751L11.4369 3.67673C15.4689 3.67673 18.6313 5.04088 20.7904 7.21792C22.9359 9.38122 24 12.2578 24 15.117C24 17.9762 22.9359 20.8528 20.7904 23.0161C18.6313 25.1931 15.4605 26.5573 11.4286 26.5573L10.6602 26.5573V32H0.0116238C0.0116238 26.3002 -0.338434 20.0623 2.80353 15.0412C0.619871 10.8961 1.21969 5.67836 2.81442 1.44161L3.35705 0L6.19757 1.10157ZM6.61751 15.1378C7.89428 16.4093 9.6814 16.9105 11.4369 16.9105C13.628 16.9105 14.1548 15.7417 14.1534 15.1134C14.152 14.4778 13.6108 13.3096 11.4369 13.3096C9.42737 13.3096 7.84916 13.9546 6.61751 15.1378Z"
          fill={`${hexCode}`}
        />
      </svg>

      {/* <RiveComponent src={'/rive/proof-logo-animation-light.riv'} className={`w-full h-full ${color === 'white' ? '' : 'hidden'}`} stateMachines={['State Machine 1']} />
      <RiveComponent src={'/rive/proof-logo-animation-dark.riv'} className={`w-full h-full ${color === 'dark' ? '' : 'hidden'}`} stateMachines={['State Machine 1']} /> */}
    </div>
  );
}

function Header({ headerDark = false, isLandingPage = false, customHeaderButton = [] }) {
  const router = useRouter();
  const [headerBg, setHeaderBg] = useState(false);
  const [showMobileNav, setShowMobileNav] = useState(false);
  const [headerDarkOverride, setHeaderDarkOverride] = useState(null);

  const scrollEvent = e => {
    if (window.scrollY > 0) {
      setHeaderBg(true);
      setHeaderDarkOverride(false);
    } else {
      setHeaderBg(false);
    }
  };

  useEffect(() => {
    showMobileNav ? document.querySelector('body').classList.add('overflow-hidden') : document.querySelector('body').classList.remove('overflow-hidden');
  }, [showMobileNav]);

  useEffect(() => {
    window.addEventListener('scroll', scrollEvent, { passive: true });

    return () => window.removeEventListener('scroll', scrollEvent);
  }, []);

  return (
    <header className={`fixed top-0 left-0 z-50 w-full select-none transition-all duration-200 ease-in-out header ${headerBg && 'bg-white'} `}>
      {/*{moment().isAfter('2022-06-12 09:00 am EDT') && moment().isBefore('2022-06-12 1:00 pm EDT') && <div className="text-xs md:text-base bg-accent w-full block text-center p-2 text-black font-semibold z-50">
                <span className={'mr-4'}>🛠</span>Proof's platform is currently offline for scheduled maintenance.
                Maintenance is scheduled to conclude at 1:00 p.m. EDT.<span className={'ml-4'}>🛠</span>
            </div>}*/}
      <div className="w-full h-full px-6 lg:px-10">
        <div className="flex items-center justify-between w-full h-16 md:h-20">
          <div className="z-50 flex items-center justify-center">
            <Link href={'/'}>
              <a className="block w-24 transition-opacity duration-200 ease-in-out contain hover:opacity-75">
                <Logo color={headerDark && !headerBg && !showMobileNav ? 'white' : 'dark'} />
              </a>
            </Link>
          </div>

          {/* header nav */}
          {!isLandingPage && <HeaderNav headerDark={headerDark} headerBg={headerBg} />}

          <div className="items-center hidden space-x-4 xl:flex">
            {!isLandingPage ? (
              <Link href="https://app.proofserve.com" target="_blank">
                <a className={`w-auto px-6 py-3 btn ${headerDark && !headerBg ? 'btn-outline-white' : 'btn-outline-black'}`}>Log in</a>
              </Link>
            ) : (
              <div className={`font-medium ${headerDark && !headerBg ? 'text-white' : 'text-black'} mr-4`} style={{ fontSize: '15px' }}>
                Serve docs nationwide in as fast as 24 hours!
              </div>
            )}

            <Link
              href={`${customHeaderButton && customHeaderButton.length > 0 && customHeaderButton[0].link.url.length > 0 ? customHeaderButton[0].link.url : 'https://app.proofserve.com/signup'}`}>
              <a
                className={`w-auto px-6 py-3 btn ${headerDark && !headerBg ? 'hover:btn-white' : ''}`}
                data-id="header-primary-cta"
                onClick={() => {
                  if (window.analytics) {
                    analytics.track('Get Started', {
                      type: 'header'
                    });
                  }
                }}>
                {customHeaderButton && customHeaderButton.length > 0 && customHeaderButton[0].title.length > 0 ? customHeaderButton[0].title : 'Get started'}
              </a>
            </Link>
          </div>

          {/* mobile nav */}

          <div className="z-50 flex items-center justify-center xl:hidden">
            <button onClick={() => setShowMobileNav(!showMobileNav)} className="flex items-center cursor-pointer focus:outline-none tg-hamburger" type="button">
              <div className="relative inline-block align-middle">
                <span
                  className={`absolute left-0 rounded-none ${headerDark && !headerBg && !showMobileNav ? 'bg-white ' : 'bg-black'} ${showMobileNav && ' transform -rotate-45'}`}
                  style={{ top: showMobileNav && '9px' }}></span>
                <span
                  className={`absolute left-0 rounded-none ${headerDark && !headerBg && !showMobileNav ? 'bg-white ' : 'bg-black '}${showMobileNav && ' transform rotate-45'} `}
                  style={{ bottom: showMobileNav && '9px' }}></span>
              </div>
            </button>
          </div>

          <AnimatePresence>{showMobileNav && <MobileNav />}</AnimatePresence>
        </div>
      </div>
    </header>
  );
}

export default Header;
