import { motion } from 'framer-motion';
import Link from 'next/link';
import React, { useState } from 'react';
import slugify from 'slugify';
import navItems from '~/data/header-nav';

function MobileNav() {
  return (
    <motion.nav
      key={`mobileNav`}
      initial={{ y: '-100%' }}
      animate={{ y: '0' }}
      exit={{ y: '-100%' }}
      className="fixed overflow-auto inset-0 z-40 justify-start h-screen px-5 pt-16 transition-all duration-300 ease-in-out bg-white xl:opacity-0 tracking-tight">
      <div className="flex flex-col min-h-full">
        <h2 className="mt-10 text-3xl font-semibold text-black" style={{lineHeight:1.15}}>
          The <span className="underswipe">smarter way</span> to serve legal documents
        </h2>
        <div className="flex flex-col flex-auto w-full pt-6 pb-6 nav-container ">
          {navItems.map((item, i) => (
            <MobileNavItems item={item} instance={i} key={i} />
          ))}
          <Link href="https://app.proofserve.com" target="_blank">
            <a className="w-full mt-8 text-center btn btn-outline-black">Log in</a>
          </Link>
          <Link href="https://app.proofserve.com/signup">
            <a data-id="mobile-nav-primary-cta" className="w-full mt-4 text-center btn ">
              Get started
            </a>
          </Link>

          <div className="pb-20 mt-6 text-sm font-medium text-center">&copy; Copyright {new Date().getFullYear()} Proof Inc.</div>
        </div>
      </div>
    </motion.nav>
  );
}

function MobileNavItems({ item, instance }) {
  const [showSubnav, setShowSubnav] = useState(false);
  return (
    <li
      key={`mobileNav${instance}`}
      onClick={item?.subItems || item?.multilevelSubItems ? () => setShowSubnav(!showSubnav) : null}
      className={`list-none relative border-b border-dashed b-light ${item.subItems || item.multilevelSubItems ? 'has-sub' : ''} `}>
      <Link href={item?.subItems?.length > 0 || item.multilevelSubItems?.length > 0 ? '#' : item.link}>
        <a className={`${item.subItems || item.multilevelSubItems ? 'sub-link' : ''} flex justify-between items-center hover:text-accent`}>
          <h5 className="py-4 mb-0 text-xl font-medium text-black duration-200 ease-in-out transition-color" dangerouslySetInnerHTML={{ __html: item.title }}/>
          {item.subItems || item.multilevelSubItems ? (
            <div className={`transform ${!showSubnav && 'rotate-180'}`}>
              <svg width="15" heitght="15" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.5 9.5L0.5 0.5" stroke="#F3B545" strokeLinejoin="round" />
                <path d="M3.5 9.5H9.5V3.5" stroke="#F3B545" />
              </svg>
            </div>
          ) : null}
        </a>
      </Link>

      {showSubnav && (item.subItems || item.multilevelSubItems) ? (
        <ul className={`w-full overflow-hidden ${item?.multilevelSubItems ? 'space-y-4' : 'grid sm:grid-cols-2'} subcontainer transition-all ease-in-out duration-200  ${item.subItems?.length || item.multilevelSubItems?.length ? '' : 'max-h-0'}`}>
          {item.subItems ? (
            <>
              {item.subItems.map((subItem, i) => (
                <SubItem subItem={subItem} key={`sub-${item.title+'link'+i}`} />
              ))}
            </>
          ): (
            <>
            {item.multilevelSubItems.map(rs => (
              <div>
                {rs.heading !== 'Resources' ? <div className="font-medium mb-2 pb-2 border-b border-dashed " style={{ borderColor: 'rgba(0,0,0,0.1)' }} dangerouslySetInnerHTML={{ __html: rs.heading }}/> : null}
                <div className="grid sm:grid-cols-2">
                  {rs.items.map((subItem, i) => (
                    <SubItem subItem={subItem} key={`sub-${rs.heading+item.title+'link'+i}`} />
                  ))}
                </div>
              </div>
            ))}
            </>
          )}

          <div className="block mb-4"></div>
        </ul>
      ) : null}
    </li>
  );
}

export default MobileNav;


function SubItem({ subItem }) {
  return (
    <Link href={subItem.link}>
      <a className="flex items-center py-1 mr-2" onClick={e => e.stopPropagation()}>
        {subItem?.icon ? (
          <div className="w-8 h-8  mr-4 bg-tertiary flex flex-col items-center justify-center subnav-item-icon" style={{ flex: '0 0 32px', padding: '6px' }}>
            <img src={`/images/menu/${subItem.icon}`} alt={slugify(subItem.title)} loading="lazy" />
          </div>
        ) : null}
        <h5 className="mb-0 text-sm font-medium duration-200 ease-in-out text-navy transition-color">{subItem.title}</h5>
      </a>
    </Link>
  );
}